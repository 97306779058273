<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'guard'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  tabindex="-1"
  aria-label={ariaLabel}
  viewBox="0 0 30 35"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M15.2905 34.9938H14.3483C13.8179 34.7584 13.2938 34.5043 12.7571 34.2875C6.46111 31.741 2.36152 27.311 0.82027 20.6629C-0.271706 15.9541 0.0215661 11.1711 0.0777249 6.4003C0.0902046 5.43995 0.726683 5.08679 1.65018 5.08059C6.54223 5.03102 10.8352 3.48203 14.342 0H15.2843C18.7848 3.48203 23.0779 5.03102 27.9699 5.07439C28.9621 5.08678 29.5798 5.50187 29.5548 6.51178C29.4363 10.6939 29.5361 14.9071 29.0681 19.0582C28.5128 24.0087 26.2914 28.3147 22.1294 31.2763C20.0328 32.7695 17.5805 33.7732 15.2843 35L15.2905 34.9938ZM27.0651 7.5217C22.379 7.31104 18.3231 5.90463 14.7976 2.91207C11.2908 5.93561 7.19118 7.32344 2.6236 7.5341C2.6236 10.5453 2.47383 13.4573 2.65479 16.3507C3.12902 23.891 6.66704 29.3556 13.949 32.1251C14.3546 32.28 14.8912 32.4163 15.2656 32.28C20.2387 30.4647 24.0263 27.3606 25.8172 22.2615C27.4832 17.5031 27.1899 12.5589 27.0714 7.5279L27.0651 7.5217Z"
  />
  <path
    d="M13.1592 19.0676C14.985 17.3004 16.668 15.6742 18.3572 14.0479C18.6305 13.7837 18.8851 13.3962 19.2142 13.3257C19.7111 13.2142 20.5308 13.1378 20.7357 13.3962C21.0152 13.7426 21.1084 14.6408 20.8413 14.9109C18.6305 17.1361 16.3265 19.279 14.0162 21.4161C13.4697 21.921 12.8177 21.8917 12.2525 21.3633C11.1719 20.3534 10.0914 19.3436 9.03561 18.3162C8.47047 17.7702 8.34625 17.1243 8.9797 16.5665C9.60693 16.0146 10.2652 16.1732 10.8242 16.7309C11.5508 17.4472 12.2649 18.1752 13.1654 19.0735L13.1592 19.0676Z"
  />
</svg>
